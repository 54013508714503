<template>
  <div v-if="parameter">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('parameter.update')"
      :title="$t('parameter.update')"
      @submitPressed="submitPressed"
    />
    <parameter-form :parameter-object="parameter" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ParameterForm from './components/ParameterForm.vue';

export default {
  components: {
    TitleBar,
    ParameterForm
  },
  data() {
    return {
      action: null,
      parameter: null
    }
  },
  created() {
    this.loadParameter()
  },
  methods: {
    loadParameter() {
      const id = this.$route.params.id;
      this.$Parameters.getResource({ id }).then((response) => {
        this.parameter = response.data
        this.parameter.type = {
          label: this.$helper.getEnumTranslation('custom_parameter_type', this.parameter.type, this.$i18n.locale),
          id: this.parameter.type
        }
      });
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>

<style lang="scss">
</style>
